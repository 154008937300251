import "./App.css";

function App() {
  return (
    <div className="flex items-center justify-center h-screen text-white">
      Hello World!
    </div>
  );
}

export default App;
